import React from 'react'
import CompanyPoints from '../components/CompanyPoints'
const Home = () => {
  return (
    <div>
      <CompanyPoints></CompanyPoints>
    </div>
  )
}

export default Home
