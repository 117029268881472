import { useState } from 'react';
import ServiceFormModal from './ServiceFormModal';
import ServicesImgs from './ServicesImgs'
export default function Services() {
  const services = [
    'Metallic & Non-Metallic Piping Supplies, Fabrication, and Installation',
    'Steel Structure Supply, Fabrication & Installation',
    'HVAC Works',
    'Rotary Equipment Maintenance and Overhauling Works',
    'Shutdowns, Turnaround Maintenance Work',
    'Hydrojetting Cleaning Works',
    'Heavy Lifting',
    'NDT & Third-Party Inspection',
    'HSE Trainings',
    'ISO Certifications',
    'Project Management Consultancy',
    'Tender Preparation',
    'CADing & Drafting',
    'FEED & Detailed Engineering',
    'Equipment Rental',
    'Manpower Supply',
    'Pest Control',
    'Civil Construction'
  ];

  const [selectedService, setSelectedService] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const displayedServices = showAll ? services : services.slice(0, 6);

  return (
   
  <>
  

  <div className="bg-gray-100 py-24 sm:py-32 lg:py-40">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
          Our Services
        </h2>
        <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {displayedServices.map((service, index) => (
            <div
              key={index}
              className="relative group rounded-lg border border-gray-200 bg-white p-6 shadow-sm hover:shadow-lg transition-shadow duration-200 hover:bg-gray-50"
            >
              <h3 className="text-lg font-medium text-gray-900">{service}</h3>
              <button
                className="mt-4 w-full rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                onClick={() => setSelectedService(service)}
              >
                Book Now
              </button>
            </div>
          ))}
        </div>
        {!showAll && (
          <div className="mt-8 flex justify-center">
            <button
              className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
              onClick={() => setShowAll(true)}
            >
              Show More
            </button>
          </div>
        )}
      </div>

      {selectedService && (
        <ServiceFormModal
          isOpen={!!selectedService}
          closeModal={() => setSelectedService(null)}
          serviceName={selectedService}
        />
      )}
    </div>
    <ServicesImgs></ServicesImgs>
  </>
  );
}
