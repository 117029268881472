import './App.css';
import Home from './pages/Home'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './components/Services'
import Hero from './components/Hero'
import Footer from './components/Footer'
import AboutPage from './pages/AboutPage';
import ContactUs from './pages/ContactUs';
function App() {
  return (
    <>
  <Router>
    <Hero></Hero>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactUs />} />
        {/* other routes */}
      </Routes>
      <Footer></Footer>
    </Router>
    </>
  );
}
export default App;
