import React from 'react';
import aboutimg from '../components/images/aboutimg.JPG';

const About = () => {
  return (
    <div className="sm:flex items-center max-w-screen-xl mx-auto my-10">
      <div className="sm:w-1/2 p-10">
        <div className="image object-center text-center">
          <img src={aboutimg} alt="About us" className="rounded-md shadow-lg"/>
        </div>
      </div>
      <div className="sm:w-1/2 p-5">
        <div className="text">
          <span className="text-gray-500 border-b-2 border-indigo-600 uppercase">About us</span>
          <h5 className="my-4 font-bold text-3xl sm:text-3xl">
            About <span className="text-indigo-600">Lead Engineering & Contracting</span>
          </h5>
          <p className="text-gray-700">
            Lead Engineering & Contracting was established in 2024 by our founder and CEO, Engineer Mr. Shahnawaz Akhtar, after serving approximately 15 years in the Gulf region, Saudi Arabia & Qatar with various oil, gas, and petrochemical companies like Saudi Aramco, Saudi Sabic, Qatar Petroleum, Qatar Gas, Dolphin Energy. With a strong foundation of project handling, engineering & construction expertise as per international standard and codes and a commitment to excellence, we provide a comprehensive range of services to meet the diverse needs of our clients.
          </p>
          <div className="mt-8 p-6 bg-indigo-100 text-indigo-900 rounded-md shadow-inner">
            <h2 className="text-xl font-semibold">Our Mission</h2>
            <p className="mt-2">
              To deliver quality and international standards engineering & contracting services to our valuable clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
















// import React from 'react'
// import aboutimg from '../components/images/aboutimg.JPG'
// const About = () => {
//   return (
//     <div class="sm:flex items-center max-w-screen-xl">
//     <div class="sm:w-1/2 p-10">
//         <div class="image object-center text-center">
//             <img src={aboutimg}/>
//         </div>
//     </div>
//     <div class="sm:w-1/2 p-5">
//         <div class="text">
//             <span class="text-gray-500 border-b-2 border-indigo-600 uppercase">About us</span>
//             <h5 class="my-4 font-bold text-3xl  sm:text-3xl ">About <span class="text-indigo-600">Lead Engineering & Contracting</span>
//             </h5>
//             <p class="text-gray-700">
//             Lead Engineering & Contracting was established in 2024 by our founder and CEO, Engineer Mr. Shahnawaz Akhtar, after serving approximately 15 years in the Gulf region, Saudi Arabia & Qatar with various oil, gas, and petrochemical companies like Saudi Aramco, Saudi Sabic,Qatar Petroleum, Qatar Gas, Dolphin Energy. With a strong foundation of project handling, engineering & construction expertise as per international standard and codes and a commitment to excellence, we provide a comprehensive range of services to meet the diverse needs of our clients.
//             </p>
//         </div>
//     </div>
// </div>
//   )
// }

// export default About
