import React from 'react';

const ContactUs = () => {
  return (
    <div className="my-6">
      <div className="grid sm:grid-cols-2 items-center gap-16 p-8 mx-auto max-w-4xl bg-white shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-[#333] font-[sans-serif]">
        <div>
          <h1 className="text-3xl font-extrabold">Let's Talk</h1>
          <p className="text-sm text-gray-400 mt-3">If you have a big idea or project to develop and need assistance, we're here to help. Reach out to us with your project details, and let's collaborate to bring your vision to life. We are committed to providing top-quality services that meet your needs.</p>
          <div className="mt-12">
            <h2 className="text-lg font-extrabold">Email</h2>
            <ul className="mt-3">
              <li className="text-[#007bff] text-sm">
                <small className="block">Email</small>
                <strong>Sales@leadeng-cont.com</strong> <br></br>
                <strong>shahnawaz_hafiz@yahoo.com</strong>
              </li>
            </ul>
          </div>
          <div className="mt-12">
            <h2 className="text-lg font-extrabold">Contact Number</h2>
            <ul className="mt-3">
              <li className="text-[#007bff] text-sm">
                <small className="block">Phone</small>
                <strong>0333-2463453</strong>
              </li> <br></br>
              <li className="text-[#007bff] text-sm">
                <small className="block">WhatsAPP</small>
                <strong>0333-2463453</strong>
              </li>
            
            </ul>
          </div>



          <div className="mt-12">
            <h2 className="text-lg font-extrabold">Address</h2>
            <ul className="mt-3">
              <li className="text-[#007bff] text-sm">
                <small className="block">Location</small>
                <strong>House Number R-169 Gohar City Malir Karachi.</strong>
              </li>
            </ul>
          </div>
        </div>

        {/* <form className="ml-auto space-y-4"> */}
        <form action="https://formspree.io/f/xleqkjwo" method="POST"  className="ml-auto space-y-4">
          <input type='text' placeholder='Name' className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]" />
          <input type='email' placeholder='Email' className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]" />
          <input type='text' placeholder='Subject' className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#007bff]" />
          <textarea placeholder='Message' rows="6" className="w-full rounded-md px-4 border text-sm pt-2.5 outline-[#007bff]"></textarea>
          <button type='button' className="text-white bg-[#007bff] hover:bg-blue-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full">Send</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
