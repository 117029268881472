import React from 'react';

const CompanyHighlights = () => {
  return (
    <div className="w-full bg-gradient-to-r from-blue-100 to-blue-200 py-8">
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Our Company Highlights</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-lg hover:bg-blue-500 transition-colors duration-300">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">Extensive Experience</h3>
            <p className="text-gray-700">Our founder and CEO, Engineer Mr. Shahnawaz Akhtar, brings 15 years of experience in the Gulf region, offering valuable expertise to every project.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:bg-blue-500 transition-colors duration-300">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">Comprehensive Services</h3>
            <p className="text-gray-700">From initial planning to project execution and beyond, we offer a wide range of services to meet all client needs under one roof.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:bg-blue-500 transition-colors duration-300">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">Commitment to Quality</h3>
            <p className="text-gray-700">We adhere to international standards and best practices to ensure the highest quality in every aspect of our work.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:bg-blue-500 transition-colors duration-300">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">Customized Solutions</h3>
            <p className="text-gray-700">We tailor our services to the specific requirements of each project, providing personalized solutions for optimal results.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:bg-blue-500 transition-colors duration-300">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">Expert Team</h3>
            <p className="text-gray-700">Our leadership team consists of industry experts with years of experience in engineering, project management, and business development.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:bg-blue-500 transition-colors duration-300">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">Achievements & Awards</h3>
            <p className="text-gray-700">Lead Engineering & Contracting has been recognized for excellence in service delivery and commitment to quality, earning accolades from clients and industry partners.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyHighlights;
