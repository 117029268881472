import React from 'react';
import si1 from '../components/images/si1.jpg';
import si2 from '../components/images/si2.jpg';
import si3 from '../components/images/si3.jpg';
import si4 from '../components/images/si4.jpg';
import si5 from '../components/images/si5.jpg';
import si6 from '../components/images/si6.jpg';
import si7 from '../components/images/si7.jpg';
import si8 from '../components/images/si8.jpg';
import si9 from '../components/images/si9.jpg';
import si10 from '../components/images/si10.jpg';
import si11 from '../components/images/si11.jpg';
import si12 from '../components/images/si12.jpg';
import si13 from '../components/images/si13.jpg';
import si14 from '../components/images/si14.jpg';
import si15 from '../components/images/si15.jpg';
import si16 from '../components/images/si16.jpg';
import si17 from '../components/images/si17.jpg';
import si18 from '../components/images/si18.jpg';
import si19 from '../components/images/si19.jpg';
import si20 from '../components/images/si20.jpg';
import si21 from '../components/images/si21.jpg';
import si22 from '../components/images/si22.jpg';

const ServicesImgs = () => {
  const images = [
    si4, si12,si7, si8 ,si5 ,si3, si6, si1, si5, si22, si9, si10,
    si11, si2, si13, si14, si15, si16, si17, si18, si19, si20 ,si21, si22
  ];

  return (
    <div className="container mx-auto">
      <h1 className="text-center text-4xl font-bold my-8">Photo Gallery</h1>
      <h3  className="text-center text-4xl font-bold my-8">previous projects in Gulf & Pakistan </h3>
      <div className="flex flex-wrap">
        {images.map((image, index) => (
          <div key={index} className="w-1/2 p-2" style={{ height: '80vh' }}>
            <img src={image} alt={`Gallery Image ${index + 1}`} className="h-full w-full object-cover hover:opacity-75 transition-opacity duration-300" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServicesImgs;
