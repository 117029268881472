import React from 'react'
import About from '../components/About'
import CompanyPoints from '../components/CompanyPoints'
const AboutPage = () => {
  return (
    <div>
      <About></About>
      {/* <CompanyPoints></CompanyPoints> */}
    </div>
  )
}

export default AboutPage
